import React from 'react';
import $ from 'jquery';
import { Dropdown, Grid } from 'semantic-ui-react';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactivePlugin from '@fullcalendar/interaction';
import moment from 'moment';

import Payroll from './payroll';
import Edit from './edit';
import server from '../../functions/server';
import Load from '../../load';
import Title from '../../product/add_product/title';
import BulkUpdate from './bulkupdate';

export default class Attendance extends React.Component {

    state = {
        edit: false,
        load: false,
        employee: null,
        employees: [],
        events: [],
        event: {}
    }

    componentDidMount() {
        this.getAllEmployees();
    }

    getAllEmployees() {
        let context = this;
        let url = server() + '/employees/getall.php';
        let data = { id: this.props.data.id };
        $.ajax({
            url: url,
            data, type: 'POST',
            beforeSend: function (x) {
                context.setState({ load: true });
            }
        }).done((data) => {
            let out = 1;
            try {
                out = JSON.parse(data);
            } catch (e) { }
            context.setState({ employees: out, load: false });
        });
    }

    getEmployeeById = (id) => {
        for (const emp of this.state.employees) {
            if (emp.id === id)
                return emp;
        }
        return {};
    }

    getPostData = () => {
        let dateOfJoining = null;
        let { employee, start, end, employees } = this.state;
        for (const emp of employees) {
            if (emp.id === employee) {
                dateOfJoining = emp.date_of_joining;
                break;
            }
        }
        let doj = moment(dateOfJoining, 'YYYY-MM-DD').valueOf();
        let now = new Date().getTime();
        start = start < doj ? doj : start;
        end = end > now ? now : end;
        if (employee < 1 || start < 1 || end < 1) return;
        let startTime = moment(start).format('YYYY-MM-DD HH:mm:ss');
        let endTime = moment(end).format('YYYY-MM-DD HH:mm:ss');
        let shop = this.props.data.id;
        let data = { id: employee, shop, from: startTime, to: endTime, start, end };
        return data;
    }

    getEvents = () => {
        let context = this;
        let url = server() + '/employees/attendance/getall.php';
        let data = this.getPostData();
        $.ajax({
            url: url,
            data, type: 'POST',
            beforeSend: function (x) {
                context.setState({ load: true });
            }
        }).done((data) => {
            let out = [];
            try {
                out = JSON.parse(data);
            } catch (e) { }
            context.setState({ events: out, load: false });
        });
    }

    updateEvent = ({ event }) => {
        let { events } = this.state;
        let obj = null;
        for (const e of events) {
            if (e.start === moment(event.start).format('YYYY-MM-DD')) {
                obj = e;
                break;
            }
        }
        if (!obj) return;
        this.setState({
            edit: true,
            event: obj
        });
    }

    render() {
        return (
            <div>
                <Title shopId={this.props.data.id} shopDetails={this.props.data} title={this.props.data.shop_name + " - Attendance"} menu={true} />
                <Load open={this.state.load} />
                <Grid>
                    <Grid.Column floated='left'>
                        <Grid>
                            <Grid.Column>
                                <Dropdown
                                    placeholder='Select Employee'
                                    style={{ margin: 10 }}
                                    selection
                                    value={this.state.employee}
                                    onChange={(e, { value }) => { 
                                        this.setState({ employee: value }, () => {
                                            this.getEvents();
                                        });
                                    }}
                                    options={this.state.employees.map((emp) => {
                                        return {
                                            key: emp.id,
                                            text: emp.custom_id + " - " + emp.name,
                                            value: emp.id,
                                        }
                                    })}
                                />
                            </Grid.Column>
                            <Grid.Column>
                                {this.state.employee > 0 && 
                                    <BulkUpdate 
                                        events={this.state.events}
                                        getPostData={this.getPostData}
                                        getEvents={this.getEvents}
                                        employee={this.getEmployeeById(this.state.employee)}/>
                                }
                            </Grid.Column>
                        </Grid>
                    </Grid.Column>
                    <Grid.Column floated='right'>
                        {/* Refresh button is inside the Payroll component */}
                        {this.state.employee > 0 &&
                            <Payroll
                                getPostData={this.getPostData}
                                getEvents={this.getEvents}
                                employee={this.getEmployeeById(this.state.employee)}/>}
                    </Grid.Column>
                </Grid>
                <Edit open={this.state.edit} event={this.state.event}
                    getEvents={this.getEvents} id={this.state.employee}
                    handleClose={() => this.setState({ edit: false })} />
                <div style={{ margin: 10, height: '100%' }}>
                    <FullCalendar
                        height={500}
                        headerToolbar={{
                            right: 'prev,next today',
                            left: 'title',
                        }}
                        plugins={[dayGridPlugin, interactivePlugin]}
                        themeSystem="bootstrap5"
                        initialView="dayGridMonth"
                        events={this.state.events}
                        datesSet={(props) => {
                            this.setState({
                                start: props.start.getTime(),
                                end: props.end.getTime()
                            }, () => {
                                this.getEvents();
                            });
                        }}
                        showNonCurrentDates={false}
                        eventClick={this.updateEvent}
                    />
                </div>
            </div>
        )
    }

}