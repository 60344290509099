import { event } from "jquery";
import moment from "moment";
import React from "react";
import { TableRow, TableCell, Form } from "semantic-ui-react";

export default function Row({ date, events, updateEvent }) {
  const getEvent = () => {
    for (const eve of events) {
      if (eve.start === moment(date).format("YYYY-MM-DD")) {
        return eve;
      }
    }
    return {};
  };

  const handleChange =
    (type) =>
    (e, { value }) => {
      updateEvent(date, type, value);
    };

  const { shift, remarks } = getEvent();

  return (
    <TableRow>
      <TableCell>{moment(date).format("DD MMM YY")}</TableCell>
      <TableCell>
        <Form>
          <Form.Group inline>
            <Form.Radio
              label="Holiday"
              value={-1}
              checked={shift < 0}
              onChange={handleChange("shift")}
            />
            <Form.Radio
              style={{ marginLeft: 60 }}
              label="Present"
              value={1}
              checked={shift > 0}
              onChange={handleChange("shift")}
            />
            <Form.Radio
              style={{ marginLeft: 60 }}
              label="Absent"
              value={0}
              checked={shift === 0}
              onChange={handleChange("shift")}
            />
          </Form.Group>
        </Form>
      </TableCell>
      <TableCell>
        <Form>
          <Form.Group inline>
            <Form.Radio
              label="0"
              value={0}
              checked={shift === 0}
              onChange={handleChange("shift")}
            />
            <Form.Radio
              style={{ marginLeft: 30 }}
              label="0.5"
              value={0.5}
              checked={shift === 0.5}
              onChange={handleChange("shift")}
            />
            <Form.Radio
              style={{ marginLeft: 30 }}
              label="1"
              value={1}
              checked={shift === 1}
              onChange={handleChange("shift")}
            />
            <Form.Radio
              style={{ marginLeft: 30 }}
              label="1.5"
              value={1.5}
              checked={shift === 1.5}
              onChange={handleChange("shift")}
            />
            <Form.Radio
              style={{ marginLeft: 30 }}
              label="2"
              value={2}
              checked={shift === 2}
              onChange={handleChange("shift")}
            />
            <Form.Radio
              style={{ marginLeft: 30 }}
              label="2.5"
              value={2.5}
              checked={shift === 2.5}
              onChange={handleChange("shift")}
            />
            <Form.Radio
              style={{ marginLeft: 30 }}
              label="3"
              value={3}
              checked={shift === 3}
              onChange={handleChange("shift")}
            />
          </Form.Group>
        </Form>
      </TableCell>
      <TableCell>
        <Form.TextArea
          value={remarks}
          onChange={handleChange("remarks")}
          placeholder="Remarks..."
        />
      </TableCell>
    </TableRow>
  );
}
