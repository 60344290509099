import React from 'react';
import $ from 'jquery';
import { Button, Icon, Modal, Table, Grid } from 'semantic-ui-react';

import server from '../../functions/server';

export default class Payroll extends React.Component {

    state = {
        load: false,
        open: false,
        data: {},
    }

    getPayrollData = () => {
        let { data } = this.state;
        if (!data || !data.transactions) {
            return (
              <p>Try Again Later</p>  
            );
        }
        let { credit, debit, salary_type } = data.transactions;
        let { esi, pf } = this.props.employee;
        let remaining = -esi + -pf + data.salary;
        return (
            <Table>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell width={10}>Salary Type</Table.HeaderCell>
                        <Table.HeaderCell width='six'
                            style={{ textTransform: 'capitalize' }}>
                            {salary_type}
                        </Table.HeaderCell>
                    </Table.Row>
                </Table.Header>

                <Table.Body>
                    <Table.Row>
                        <Table.Cell>Shifts - Working (Total)</Table.Cell>
                        <Table.Cell>{`${data.workingShifts} (${data.totalShifts})`}</Table.Cell>
                    </Table.Row>
                    <Table.Row>
                        <Table.Cell>Total Earnings</Table.Cell>
                        <Table.Cell>{parseFloat(data.salary).toFixed(2)}</Table.Cell>
                    </Table.Row>
                    <Table.Row>
                        <Table.Cell>PF</Table.Cell>
                        <Table.Cell>{pf}</Table.Cell>
                    </Table.Row>
                    <Table.Row>
                        <Table.Cell>ESI</Table.Cell>
                        <Table.Cell>{esi}</Table.Cell>
                    </Table.Row>
                </Table.Body>

                <Table.Footer>
                    <Table.Row>
                        <Table.HeaderCell>Net Pay</Table.HeaderCell>
                        <Table.HeaderCell>{parseFloat(remaining).toFixed(2)}</Table.HeaderCell>
                    </Table.Row>
                    <Table.Row>
                        <Table.HeaderCell>Paid</Table.HeaderCell>
                        <Table.HeaderCell>{parseFloat(debit).toFixed(2)}</Table.HeaderCell>
                    </Table.Row>
                    <Table.Row>
                        <Table.HeaderCell>Balance to Pay</Table.HeaderCell>
                        <Table.HeaderCell>{parseFloat(remaining - debit).toFixed(2)}</Table.HeaderCell>
                    </Table.Row>
                </Table.Footer>
            </Table>
        )
    }

    getDetails = () => {
        let context = this;
        let url = server() + '/employees/payroll/getdetails.php';
        let data = this.props.getPostData();
        $.ajax({
            url: url,
            data, type: 'POST',
            beforeSend: function (x) {
                context.setState({ load: true });
            }
        }).done((data) => {
            let out = [];
            try {
                out = JSON.parse(data);
            } catch (e) { }
            context.setState({ data: out, load: false, open: true });
        });
    }

    render() {
        let { load, open, data } = this.state;
        return (
            <div>
                <Grid>
                    <Grid.Column>
                        <Button icon labelPosition='left'
                            color='primary'
                            style={{ marginTop: 10, marginLeft: -220 }}
                            onClick={this.props.getEvents}>
                            <Icon name='refresh' />
                            Refresh
                        </Button>
                    </Grid.Column>
                    <Grid.Column>
                        <Button icon labelPosition='left'
                            color='primary' loading={load}
                            style={{ marginTop: 10, marginLeft: -110 }}
                            onClick={this.getDetails}>
                            <Icon name='money' />
                            Payroll
                        </Button>
                    </Grid.Column>
                </Grid>
                <Modal
                    onClose={() => this.setState({ open: false })}
                    onOpen={() => this.setState({ open: true })}
                    open={open && data}>
                    <Modal.Header>Payroll Details</Modal.Header>
                    <Modal.Content>
                        {this.getPayrollData()}
                    </Modal.Content>
                    <Modal.Actions>
                        <Button
                            content="Close"
                            labelPosition='right'
                            icon='checkmark'
                            onClick={() => this.setState({ open: false })}
                            positive
                        />
                    </Modal.Actions>
                </Modal>
            </div>
        );
    }

}