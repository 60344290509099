import moment from "moment";
import $ from 'jquery';
import React, { useEffect, useState } from "react";
import {
  ModalHeader,
  ModalDescription,
  ModalContent,
  ModalActions,
  Button,
  Table,
  TableHeader,
  TableHeaderCell,
  TableBody,
  TableRow,
  Modal,
} from "semantic-ui-react";

import server from '../../../functions/server';
import Row from "./row";

export default function BulkUpdate({ employee, getPostData, events: eve, getEvents }) {
  const [open, setOpen] = useState(false);
  const [load, setLoad] = useState(false);
  const [events, setEvents] = useState([]);

  useEffect(() => {
    if (open) setEvents([...eve]);
    setLoad(false);
  }, [open, eve]);

  const save = () => {
    let url = server() + "/employees/attendance/bulkupdate.php";
    let data = { id: employee.id, events: JSON.stringify(events) };
    $.ajax({
      url: url,
      data,
      type: "POST",
      beforeSend: function (x) {
        setLoad(true);
      },
    }).done((data) => {
      setLoad(false);
      setOpen(false);
      getEvents();
    });
  };

  const getDates = () => {
    let dates = [];
    let { start, end } = getPostData();
    let startM = moment(start);
    let endM = moment(end);
    while (startM.isSameOrBefore(endM, "day")) {
      dates.push(startM.valueOf());
      startM.add(1, "day");
    }
    return dates;
  };

  const updateEvent = (date, type, value) => {
    let eventsTemp = [...events];
    for (let i = 0; i < eventsTemp.length; i++) {
      if (eventsTemp[i].start === moment(date).format("YYYY-MM-DD")) {
        eventsTemp[i][type] = value;
      }
    }
    setEvents(eventsTemp);
  };

  return (
    <Modal
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      open={open}
      size="fullscreen"
      trigger={
        <Button
          color="primary"
          style={{ marginLeft: 200, marginTop: 10, minWidth: 150 }}
        >
          Bulk Update
        </Button>
      }
    >
      <ModalHeader>{employee.id + " - " + employee.name}</ModalHeader>
      <ModalContent scrolling>
        <ModalDescription>
          <Table unstackable>
            <TableHeader>
              <TableRow>
                <TableHeaderCell>Date</TableHeaderCell>
                <TableHeaderCell>Status</TableHeaderCell>
                <TableHeaderCell>Shifts</TableHeaderCell>
                <TableHeaderCell>Remarks</TableHeaderCell>
              </TableRow>
            </TableHeader>

            <TableBody>
              {getDates().map((date) => {
                return (
                  <Row date={date} events={events} updateEvent={updateEvent} />
                );
              })}
            </TableBody>
          </Table>
        </ModalDescription>
      </ModalContent>
      <ModalActions>
        <Button color="black" onClick={() => setOpen(false)}>
          Cancel
        </Button>
        <Button
          content="Save Changes"
          labelPosition="right"
          icon="checkmark"
          loading={load}
          onClick={save}
          positive
        />
      </ModalActions>
    </Modal>
  );
}
