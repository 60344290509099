import React from 'react';
import $ from 'jquery';
import moment from 'moment';
import { Button, Header, Icon, Modal, Form } from 'semantic-ui-react';

import server from '../../functions/server';

export default class Edit extends React.Component {

    state = {
        id: 0,
        shift: 0,
        remarks: '',
        date: moment(new Date()).format('YYYY-MM-DD'),
        loading: false
    }

    componentDidUpdate() {
        let { event, id } = this.props;
        let { date } = this.state;
        if (date === event.start && this.state.id === id) return;
        this.setState({
            shift: event.shift,
            remarks: event.remarks,
            date: event.start,
            id
        });
    }

    editEvent = () => {
        let { id, shift, date, remarks } = this.state;
        let context = this;
        let url = server() + '/employees/attendance/edit.php';
        let data = { id, shift, date, remarks };
        $.ajax({
            url: url,
            data, type: 'POST',
            beforeSend: function (x) {
                context.setState({ load: true });
            }
        }).done((data) => {
            this.props.handleClose();
            this.props.getEvents();
        });
    }

    handleChange = (type) => (e, { value }) => {
        this.setState({
            [type]: value
        });
    }

    render() {
        let { open, handleClose } = this.props;
        let { shift, remarks, date, loading } = this.state;
        return (
            <Modal
                closeIcon
                open={open}
                >
                <Header icon='pencil' content={'Edit - ' + moment(date, 'YYYY-MM-DD').format('DD MMM YY')} />
                <Modal.Content>
                    <Form>
                        <Form.Group inline>
                            <Form.Radio
                                label='Holiday'
                                value={-1}
                                checked={shift < 0}
                                onChange={this.handleChange('shift')}
                            />
                            <Form.Radio
                                label='Present'
                                value={1}
                                checked={shift > 0}
                                onChange={this.handleChange('shift')}
                            />
                            <Form.Radio
                                label='Absent'
                                value={0}
                                checked={shift < 0.5 && shift >= 0}
                                onChange={this.handleChange('shift')}
                            />
                        </Form.Group>
                        <Form.Group inline>
                            <label>Shift</label>
                            <Form.Radio
                                label='0'
                                value={0}
                                checked={shift === 0}
                                onChange={this.handleChange('shift')}
                            />
                            <Form.Radio
                                label='0.5'
                                value={0.5}
                                checked={shift === 0.5}
                                onChange={this.handleChange('shift')}
                            />
                            <Form.Radio
                                label='1'
                                value={1}
                                checked={shift === 1}
                                onChange={this.handleChange('shift')}
                            />
                            <Form.Radio
                                label='1.5'
                                value={1.5}
                                checked={shift === 1.5}
                                onChange={this.handleChange('shift')}
                            />
                            <Form.Radio
                                label='2'
                                value={2}
                                checked={shift === 2}
                                onChange={this.handleChange('shift')}
                            />
                            <Form.Radio
                                label='2.5'
                                value={2.5}
                                checked={shift === 2.5}
                                onChange={this.handleChange('shift')}
                            />
                            
                            <Form.Radio
                                label='3'
                                value={3}
                                checked={shift === 3}
                                onChange={this.handleChange('shift')}
                            />
                        </Form.Group>
                        <Form.TextArea
                            value={remarks}
                            onChange={this.handleChange('remarks')}
                            placeholder='Remarks...' />
                    </Form>
                </Modal.Content>
                <Modal.Actions>
                    <Button color='red' onClick={() => handleClose()}>
                        <Icon name='remove' /> Cancel
                    </Button>
                    {!loading && <Button color='green' onClick={() => this.editEvent()}>
                        <Icon name='checkmark' /> Save
                    </Button>}
                    {loading && <Button color='green' loading>
                        Loading
                    </Button>}
                </Modal.Actions>
            </Modal>
        );
    }

}