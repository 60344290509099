import React from 'react';
import $ from 'jquery';
import { Form, Button, Modal, Header, Icon, Dropdown } from 'semantic-ui-react';
import TextField from '@material-ui/core/TextField';

import domain from '../../functions/domain';
import server from '../../functions/server';
import Load from '../../load';

const genderOptions = [
  {
    key: 'Male',
    text: 'Male',
    value: 'M',
  },
  {
    key: 'Female',
    text: 'Female',
    value: 'F',
  }
];

export default class Update extends React.Component {

  state = {
    open: false,
    designation: null,
    doj: this.getFormatedDate(new Date()),
  }

  componentDidMount() {
    this.setValues();
  }

  getFormatedDate(dates, interval) {
    if (interval > 0) {
      dates = new Date(dates.getTime() - (interval * 24 * 60 * 60 * 1000));
    }
    let month = dates.getMonth() + 1; let date = dates.getDate();
    month = (month > 10) ? month : "0" + month;
    date = (date > 10) ? date : "0" + date;
    let x = dates.getFullYear() + "-" + month + "-" + date;
    return x;
  }

  setValues() {
    let item = this.props.item;
    if (!item) {
      return;
    }
    let {
      id, custom_id, name, designation_id, mobile, secondary_mobile,
      email, address, city, state, country, gender, date_of_joining, salary, 
      salary_type, esi, pf 
    } = item;
    designation_id = designation_id > 0 ? designation_id : null;
    this.setState({
      id, code: custom_id, name, designation: designation_id, mobile,
      sec_mobile: secondary_mobile, mail: email, address, city,
      state, country, gender, doj: date_of_joining.split(" ")[0], salary, 
      salaryType: salary_type, esi, pf 
    })
  }

  handleClose = () => { this.setState({ open: false }); }

  handleChange = (key) => (e, { value }) => { let state = this.state; state[key] = value; this.setState(state); };

  checkboxChanges(value) {
    if (!value) {
      this.setState({ publish: true })
    }
    else {
      this.setState({ publish: false })
    }
  }

  checkData(edit) {
    let {
      code,
      name,
      gender,
      mobile,
      designation,
      salaryType,
      salary
    } = this.state;
    let value = 1;
    if (!code || code.length < 1) {
      return "Invalid Emp Code";
    } else if (!name || name.length < 2) {
      return "Invalid Name";
    } else if (!gender || gender.length < 1) {
      return "Please choose gender";
    } else if (!mobile || mobile.length < 5) {
      return "Invalid Mobile Number";
    } else if (!designation || designation < 1) {
      return "Invalid Designation";
    } else if (!salaryType || salaryType.length < 2) {
      return "Please choose salary type";
    } else if (isNaN(salary) || salary < 1) {
      return "Invalid Salary";
    }
    this.props.out.map((v) => {
      if (!edit && code == v.custom_id) {
        value = "Emp Code is Already Used";
      }
      if (!edit && this.state.mobile == v.mobile) {
        value = "Mobile Number is Already Used";
      }
    });
    return value;
  }

  update = () => {
    let { edit } = this.props;
    let context = this;
    let checkData = this.checkData(edit);
    if (checkData != 1) {
      alert(checkData);
      return;
    }
    let url = server() + '/employees/add.php';
    if (edit) {
      url = server() + '/employees/edit.php';
    }
    let {
      id, code, name, mobile, sec_mobile, mail, address, city, state,
      country, gender, doj, designation, salaryType, salary, esi, pf
    } = this.state;
    let data = {
      code, name, mobile, sec_mobile, mail, address, city, state,
      country, gender, doj, designation, salary_type: salaryType, salary, esi, pf
    };
    data["id"] = edit ? id : this.props.data.id;
    $.ajax({
      url: url,
      data, type: 'POST',
      beforeSend: function (x) {
        context.setState({ load: true });
      }
    }).done((data) => {
      console.log(data);
      if (data === "1") {
        context.props.getAllEmployees();
        context.handleClose();
      } else { alert("Error Saving"); }
      context.setState({ load: false });
    });
  }

  render() {
    let { edit } = this.props;
    return (
      <Modal trigger={
        <Button positive
          style={{ marginLeft: 5 }}
          onClick={() => { this.setState({ open: true }) }}>
          <Icon name={edit ? "pencil" : "add"} />
          {edit ? "Edit" : "Add Employee"}
        </Button>
      } open={this.state.open}>
        <Modal.Header>{edit ? "Edit" : "Add"}</Modal.Header>
        <Modal.Content>
          <Form>
            <Form.Group widths='equal'>
              <Form.Input fluid label='Employee Code (*)' placeholder='Employee Code'
                value={this.state.code} onChange={this.handleChange('code')} />
              <Form.Input fluid label='Name (*)' placeholder='Name'
                value={this.state.name} onChange={this.handleChange('name')} />
              <Form.Dropdown fluid label='Gender' placeholder='Gender'
                options={genderOptions} selection
                value={this.state.gender} onChange={this.handleChange('gender')} />
            </Form.Group>
            <Form.Group widths='equal'>
              <Form.Input fluid label='Mobile' placeholder='Mobile'
                value={this.state.mobile} onChange={this.handleChange('mobile')} />
              <Form.Input fluid label='Secondary Mobile' placeholder='Secondary Mobile'
                value={this.state.sec_mobile} onChange={this.handleChange('sec_mobile')} />
              <Form.Input fluid label='Email' placeholder='Email'
                value={this.state.mail} onChange={this.handleChange('mail')} />
            </Form.Group>
            <Form.Group widths='equal'>
              <Form.Input fluid label='Address' placeholder='Address'
                value={this.state.address} onChange={this.handleChange('address')} />
              <Form.Input fluid label='City' placeholder='City'
                value={this.state.city} onChange={this.handleChange('city')} />
              <Form.Input fluid label='State' placeholder='State'
                value={this.state.state} onChange={this.handleChange('state')} />
            </Form.Group>
            <Form.Group>
              <Form.Group inline style={{ marginTop: 10 }}>
                <label>Salary Type</label>
                <Form.Radio
                  label='Shift'
                  value='shift'
                  checked={this.state.salaryType === 'shift'}
                  onChange={this.handleChange("salaryType")}
                />
                <Form.Radio
                  label='Month'
                  value='month'
                  checked={this.state.salaryType === 'month'}
                  onChange={this.handleChange("salaryType")}
                />
              </Form.Group>
              <div style={{ marginLeft: 20 }}>
                <Form.Input label='Salary' placeholder='Salary'
                    value={this.state.salary} onChange={this.handleChange('salary')} />
              </div>
              <div style={{ marginLeft: 20 }}>
                <Form.Input label='ESI' placeholder='ESI'
                    value={this.state.esi} onChange={this.handleChange('esi')} />
              </div>
              <div style={{ marginLeft: 20 }}>
                <Form.Input label='PF' placeholder='PF'
                    value={this.state.pf} onChange={this.handleChange('pf')} />
              </div>
            </Form.Group>
            <TextField
              label="Date of Joining" type="date"
              onChange={(e) => this.setState({ doj: e.target.value })}
              defaultValue={this.state.doj} />
            <Dropdown placeholder='Designation (*)' search selection
              style={{ margin: 16 }} onChange={this.handleChange('designation')}
              value={this.state.designation} options={this.props.designations.map((d) => {
                return {
                  key: d.id, value: d.id, text: d.designation
                }
              })}/>
          </Form>
          <Load open={this.state.load} />
        </Modal.Content>
        <Modal.Actions>
          <Button negative onClick={this.handleClose}>Close</Button>
          <Button positive icon='checkmark' labelPosition='right' content='Save' onClick={this.update} />
        </Modal.Actions>
      </Modal>
    );
  }

}
